import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import useCalculatedMetricSpecs from '../services/useCalculatedMetricSpecs';
import ReadOnlyTooltip from '../common/ReadOnlyTooltip';
import Spinner from '../common/Spinner';
import theme from '../theme/theme';
import FilterBar from './components/FilterBar';
import ProjectsDataGrid from './components/ProjectsDataGrid';
import locationService from './services/locationsService';
import projectService, { ProjectListFilter } from './services/projectService';
import { Client, ClientOptions } from '@microsoft/microsoft-graph-client';
import { Providers } from '@microsoft/mgt-element';
import { Project, User } from '../api/sentinel';
import { authService } from '../authentication/useAuth';

const ProjectsList = () => {
    const { t } = useTranslation();
    const translation = {
        btnText: t('projectList_addBtn'),
        title: t('projectList_title'),
    };
    const [filter, setFilter] = useState<ProjectListFilter>({
        status: 'active',
        locationId: undefined,
    });

    const queryLocations = useQuery('locations', locationService.getLocations);
    const queryProjects = useQuery(['project', filter], () =>
        projectService.get(filter),
    );

    const { calculatedMetricSpecs } = useCalculatedMetricSpecs();

    const showOverallScore = authService.hasRole(
        calculatedMetricSpecs['overallScore']?.restriction?.readAccessRole,
    );

    const options: ClientOptions = {
        authProvider: Providers.globalProvider,
    };
    const client = Client.initWithMiddleware(options);

    const getGraphUser = async (user: User) => {
        try {
            const response = await client.api('/users/' + user.userId).get();
            return Object.assign(user, response);
        } catch {
            () => {
                // default behavior when graph api returns an error.
                Object.assign(user, { displayName: user.upn });
            };
        }
    };

    const [decoratedProjects, setDecoratedProjects] = useState<Project[]>([]);
    const [decoratedProjectsLoading, setDecoratedProjectsLoading] =
        useState(true);

    useEffect(() => {
        const decorateProjects = async () => {
            if (queryProjects.data != undefined) {
                const decoratedProjects = await Promise.all(
                    queryProjects.data.map(async project => {
                        const scrumMaster =
                            project.scrumMaster &&
                            (await getGraphUser(project.scrumMaster));
                        const practicePartner =
                            project.practicePartner &&
                            (await getGraphUser(project.practicePartner));
                        const engagementManager =
                            project.engagementManager &&
                            (await getGraphUser(project.engagementManager));

                        return {
                            ...project,
                            scrumMaster: scrumMaster || project.scrumMaster,
                            practicePartner:
                                practicePartner || project.practicePartner,
                            engagementManager:
                                engagementManager || project.engagementManager,
                        };
                    }),
                );
                setDecoratedProjectsLoading(false);
                setDecoratedProjects(decoratedProjects);
            }
        };
        decorateProjects();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryProjects?.data]);

    if (
        queryLocations.isLoading ||
        queryProjects.isLoading ||
        decoratedProjectsLoading
    ) {
        return <Spinner open={true} />;
    }
    return (
        <Box sx={{ backgroundColor: theme.palette.grey['100'] }}>
            <Container
                maxWidth={false}
                sx={{
                    backgroundColor: theme.palette.common.white,
                    padding: '2rem 0',
                }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography variant="h1" component="h1">
                                {translation.title}
                            </Typography>
                            <ReadOnlyTooltip
                                childDisabled={!authService.hasEditingRights()}
                            >
                                <Button
                                    startIcon={<AddIcon />}
                                    size="small"
                                    color="primary"
                                    href={'/Project'}
                                    disabled={!authService.hasEditingRights()}
                                    data-testid="addProjectButton"
                                >
                                    {translation.btnText}
                                </Button>
                            </ReadOnlyTooltip>
                        </Stack>
                        <FilterBar
                            filter={filter}
                            setFilter={setFilter}
                            locations={queryLocations.data ?? []}
                        />
                        <ProjectsDataGrid
                            projects={decoratedProjects ?? []}
                            activeFilter={filter.status !== 'inactive'}
                            showOverallScore={showOverallScore}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default ProjectsList;
