import { ProtocolMode } from '@azure/msal-browser';
import config from '../services/config';

const sharedSecretToken: string | undefined = config.get(
    'REACT_APP_USE_FAKE_TOKEN',
);

const getPcaConfig = () => ({
    auth: {
        clientId: config.get('REACT_APP_AUTH_CLIENT_ID') as string,
        redirectUri: config.get('REACT_APP_AUTH_REDIRECT_URI') as string,
        authority: config.get('REACT_APP_AUTH_AUTHORITY') as string,
        knownAuthorities: [config.get('REACT_APP_AUTH_AUTHORITY') as string],
        protocolMode: ProtocolMode.OIDC,
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: true,
    },
});

const apiLoginScopes: string[] = [
    config.get('REACT_APP_AUTH_SCOPES') as string,
];

const graphLoginScopes: string[] = [
    config.get('REACT_APP_GRAPH_SCOPES') as string,
];

export { apiLoginScopes, graphLoginScopes, getPcaConfig, sharedSecretToken };
