import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Grid,
} from '@mui/material';
import CheckBoxList from '../../common/formContextBoundControls/CheckBoxList';
import useDataPointConfiguration, {
    ProjectDataPointDefinition,
} from '../../services/useDataPointConfiguration';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import theme from '../../theme/theme';

const ProjectDataPointsConfiguration = (props: { name: string }) => {
    const { data } = useDataPointConfiguration();
    const { t: translate } = useTranslation();
    const dataPointsConfiguration = data ?? [];

    function getCategoryWithMembersMap(
        dataPointConfiguration: ProjectDataPointDefinition,
        dataPointsConfiguration: ProjectDataPointDefinition[],
    ) {
        const categoryMembersMap = new Map<string, string[]>();

        function isCategoryWithMembers(dataPointId: string) {
            return (item: ProjectDataPointDefinition) =>
                item.items.some(
                    collectFieldData =>
                        collectFieldData.dataPoint.id.length >
                            dataPointId.length &&
                        collectFieldData.dataPoint.id.startsWith(dataPointId) &&
                        collectFieldData.dataPoint.type == 'MEMBER',
                );
        }

        dataPointConfiguration.items.forEach(collectFieldData => {
            const categoryWithMembers = dataPointsConfiguration.find(
                isCategoryWithMembers(collectFieldData.dataPoint.id),
            );
            categoryWithMembers &&
                categoryMembersMap.set(
                    collectFieldData.dataPoint.id,
                    categoryWithMembers.items.map(item => item.dataPoint.id),
                );
        });
        return categoryMembersMap;
    }

    return (
        <Accordion>
            <AccordionSummary
                sx={{ minHeight: '1px' }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
            >
                {translate('projectForm_dataPoint_configuration_title')}
            </AccordionSummary>
            <AccordionDetails>
                <Box mb="24px" fontSize="14px" color={theme.palette.grey[600]}>
                    {translate('projectForm_dataPoint_configuration_subtitle')}
                </Box>
                <Grid container spacing={4}>
                    {dataPointsConfiguration
                        .filter(
                            dataPointsConfiguration =>
                                !dataPointsConfiguration.items.some(
                                    member => member.dataPoint.type == 'MEMBER',
                                ),
                        )
                        .map(dataPointConfiguration => (
                            <Grid
                                item
                                key={dataPointConfiguration.category}
                                xs={12}
                                sm={6}
                            >
                                <CheckBoxList
                                    name={props.name}
                                    label={
                                        dataPointConfiguration.categoryCaption ??
                                        ''
                                    }
                                    items={dataPointConfiguration.items.map(
                                        item => ({
                                            value: String(item.dataPoint.id),
                                            label: translate(
                                                item.caption.title ?? '',
                                            ),
                                        }),
                                    )}
                                    disabled={true}
                                    categorywithmembersmap={getCategoryWithMembersMap(
                                        dataPointConfiguration,
                                        dataPointsConfiguration,
                                    )}
                                />
                            </Grid>
                        ))}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default ProjectDataPointsConfiguration;
