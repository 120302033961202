import Input from '@mui/material/Input';
import CollectFieldData from './CollectFieldData';
import NumberCollector from './types/NumberCollector';
import SelectCollector from './types/SelectCollector';

const Collector = ({
    fieldData,
    hasRole,
}: {
    fieldData: CollectFieldData;
    hasRole: (a?: string[]) => boolean;
}) => {
    const hasReadAccess = fieldData.dataPoint.restriction?.readAccessRoles
        ? hasRole(Array.from(fieldData.dataPoint.restriction.readAccessRoles))
        : true;
    const hasReadAccessAndOfType = (type: string): boolean => {
        return hasReadAccess && fieldData.dataPoint.type === type;
    };
    return (
        <>
            {hasReadAccessAndOfType('INTEGER') && (
                <NumberCollector fieldData={fieldData} hasRole={hasRole} />
            )}
            {hasReadAccessAndOfType('DECIMAL') && (
                <NumberCollector
                    fieldData={fieldData}
                    hasRole={hasRole}
                    allowsDecimal={true}
                />
            )}
            {hasReadAccessAndOfType('PERCENTAGE') && (
                <NumberCollector
                    fieldData={fieldData}
                    hasRole={hasRole}
                    allowsDecimal={true}
                    unit="%"
                />
            )}
            {hasReadAccessAndOfType('ENUMERATION') && (
                <SelectCollector fieldData={fieldData} hasRole={hasRole} />
            )}

            {!hasReadAccess && (
                <Input type="hidden" id={fieldData.dataPoint.id} />
            )}
        </>
    );
};

export default Collector;
