import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { apiLoginScopes } from './authConfig';
import {
    MsalToken,
    MsalTokenProviderBuilder,
    Providers,
    Token,
} from './tokenProvider';
import { Providers as MSProviders } from '@microsoft/mgt-element/dist/es6/providers/Providers';
import { ProviderState, SimpleProvider } from '@microsoft/mgt-element';
import { intersect } from '../utils/util';
import config from '../services/config';

let readerRole: string;
let editorRole: string;
let leaderRole: string;
let _token: Token;

export default function (usingMsal: boolean) {
    const isAuthenticated = useIsAuthenticated();
    const { inProgress, instance } = useMsal();
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        async function init(): Promise<Token | undefined> {
            if (usingMsal) {
                Providers.applicationApiProvider = new MsalTokenProviderBuilder(
                    instance,
                )
                    .api()
                    .build();
            }

            const token: Token | null = await Providers.applicationApiProvider
                .getToken()
                .then(token => (_token = token as MsalToken))
                .catch(error => {
                    console.error(error);
                    return null;
                });

            if (
                (usingMsal &&
                    inProgress === InteractionStatus.None &&
                    !isAuthenticated) ||
                (isAuthenticated && (token == null || token.expired()))
            ) {
                await instance
                    .loginRedirect({ scopes: apiLoginScopes })
                    .catch(error => console.error(error));
                return await Providers.applicationApiProvider.getToken();
            } else if (token !== null && !token.expired()) {
                return token;
            }
        }

        init()
            .then(() => {
                if (usingMsal) {
                    Providers.graphApiProvider = new MsalTokenProviderBuilder(
                        instance,
                    )
                        .graph(async () => {
                            const msalToken: MsalToken =
                                await Providers.applicationApiProvider
                                    .getToken()
                                    .then(token => token as MsalToken);
                            return msalToken.account;
                        })
                        .build();
                }
                return instance;
            }) // setup graph token provider
            .then(instance => {
                if (usingMsal) {
                    MSProviders.globalProvider = new SimpleProvider(
                        async () => {
                            const token: Token | null =
                                await Providers.graphApiProvider.getToken();
                            return Promise.resolve(token?.value());
                        },
                    );
                }
                MSProviders.globalProvider.setState(ProviderState.SignedIn);
                return instance;
            }) // setup global provider
            .then(token => {
                setIsReady(!!token);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inProgress, isAuthenticated, usingMsal]);

    return { isReady };
}

function setAppRoles() {
    readerRole = config.get('REACT_APP_READER_ROLE_NAME') as string;
    editorRole = config.get('REACT_APP_EDITOR_ROLE_NAME') as string;
    leaderRole = config.get('REACT_APP_LEADER_ROLE_NAME') as string;
}

function isAllowed(roles?: string[]) {
    setAppRoles();
    return !roles?.length || hasAnyPermittedRoles(roles);
}

function hasReadRights() {
    setAppRoles();
    return hasAnyPermittedRoles([readerRole, editorRole, leaderRole]);
}

function hasEditingRights() {
    setAppRoles();
    return hasAnyPermittedRoles([editorRole, leaderRole]);
}

function hasLeadRights() {
    setAppRoles();
    return hasAnyPermittedRoles([leaderRole]);
}

function hasRole(roleName = '') {
    return hasAnyPermittedRoles([roleName]);
}

function hasAnyPermittedRoles(permittedRoles: string[]) {
    return intersect(permittedRoles, _token.userInfo().roles ?? []).length > 0;
}

function cacheToken(token: Token) {
    _token = token;
}

export const authService = {
    hasRole,
    hasEditingRights,
    hasReadRights,
    hasLeadRights,
    isAllowed,
    cacheToken,
};
