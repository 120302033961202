import CollectFieldData from '../CollectFieldData';
import SelectInput from '../../common/formContextBoundControls/SelectInput';
import { useTranslation } from 'react-i18next';

const SelectCollector = ({
    fieldData,
    hasRole,
}: {
    fieldData: CollectFieldData;
    hasRole: (a?: string[]) => boolean;
}) => {
    const { t } = useTranslation();
    const givenOptions = fieldData?.dataPoint?.options
        ? fieldData?.dataPoint?.options
        : [];
    const options = [
        ...(fieldData?.dataPoint?.valueConstraint?.required
            ? []
            : [{ value: '', label: t('global_select_option') }]),
        ...Array.from(givenOptions).map(key => ({
            value: key,
            label: t(key),
        })),
    ];

    return (
        <>
            <SelectInput
                label={fieldData.caption.title}
                name={fieldData.dataPoint.id}
                options={options}
                tooltip_title={fieldData.caption.help}
                helper_text={fieldData.caption.directiveText}
                required={
                    fieldData.dataPoint.valueConstraint?.required ?? false
                }
                disabled={
                    fieldData.dataPoint.restriction?.writeAccessRoles
                        ? !hasRole(
                              Array.from(
                                  fieldData.dataPoint.restriction
                                      .writeAccessRoles,
                              ),
                          )
                        : false
                }
                placeholder={fieldData.caption.placeholder}
            />
        </>
    );
};

export default SelectCollector;
