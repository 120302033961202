import {
    useController,
    UseControllerReturn,
    useFormContext,
} from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import { labelBuilder } from './labelBuilder';

export interface InputProps {
    name: string;
    placeholder?: string;
    label?: string;
    items: { value: string; label: string }[];
    categorywithmembersmap: Map<string, string[]>;
    required?: boolean;
    disabled?: boolean;
}

const CheckBoxList = (props: InputProps) => {
    const { control, getValues } = useFormContext();

    const controller: UseControllerReturn = useController({
        name: props.name,
        control,
    });

    if (!Array.isArray(getValues()[controller.field.name])) {
        throw new TypeError(`target field for ${props.name} is not an array`);
    }

    const handleCheck = (value: string) => {
        const values = getValues()[controller.field.name];
        if (values.includes(value)) {
            return values
                .filter((i: string) => i !== value)
                .filter(
                    (i: string) =>
                        !(props.categorywithmembersmap?.get(value) ?? []).some(
                            c => c == i || c.startsWith(i),
                        ),
                );
        }
        return [
            ...values,
            value,
            ...(props.categorywithmembersmap?.get(value) ?? []),
        ];
    };

    return (
        <FormControl
            component="fieldset"
            fullWidth
            error={!!controller.fieldState.error}
            variant="standard"
        >
            {props.label && (
                <FormLabel component="legend">
                    {labelBuilder(props.label, props.required)}
                </FormLabel>
            )}
            {!!controller.fieldState.error && (
                <FormHelperText>
                    {controller.fieldState.error?.message}
                </FormHelperText>
            )}
            <FormGroup>
                {props.items.map(item => (
                    <FormControlLabel
                        disabled={props.disabled}
                        key={item.value}
                        control={
                            <Checkbox
                                checked={controller.field.value.includes(
                                    item.value,
                                )}
                                name={props.name}
                                value={item.value}
                                onChange={() =>
                                    controller.field.onChange(
                                        handleCheck(item.value ?? ''),
                                    )
                                }
                                data-testid={props.name}
                                id={
                                    'sentinel-checkbox-' +
                                    props.name +
                                    '-' +
                                    item.value
                                }
                            />
                        }
                        label={item.label}
                    />
                ))}
            </FormGroup>
        </FormControl>
    );
};

export default CheckBoxList;
