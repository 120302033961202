import Typography from '@mui/material/Typography';
import { InputLabel } from '@mui/material';
import theme from '../../theme/theme';
import { useTranslation } from 'react-i18next';
import OverflowTooltip from '../../common/OverflowTooltip';

const SprintGoal = ({
    message,
    status,
}: {
    message?: string;
    status?: 'Y' | 'N';
}) => {
    const { t } = useTranslation();
    const translation = {
        met: t('sprint_goal_met_label'),
        notMet: t('sprint_goal_not_met_label'),
        notSet: t('sprint_goal_not_set_label'),
        sprintGoalLabel: t('sprint_goal_label'),
    };

    const getLabel = () => {
        const isGoalMet = status === 'Y';

        let statusText = isGoalMet ? translation.met : translation.notMet;
        if (!message) {
            statusText = translation.notSet;
        }

        const suffixStyle = {
            color: isGoalMet
                ? theme.intensity.green
                : !message
                  ? undefined
                  : theme.intensity.orange,
        };

        return (
            <>
                {translation.sprintGoalLabel} (
                <b style={suffixStyle}>{statusText}</b>)
            </>
        );
    };

    return (
        <>
            <InputLabel size="normal" sx={{ fontSize: '12px' }}>
                {getLabel()}
            </InputLabel>
            {message && (
                <OverflowTooltip title={message}>
                    <Typography variant="body1">{message}</Typography>
                </OverflowTooltip>
            )}
        </>
    );
};
export default SprintGoal;
