import CheckBoxList from '../../common/formContextBoundControls/CheckBoxList';
import useLocations from '../useLocations';

const ProjectLocations = (props: { name: string; label: string }) => {
    const { data, isLoading, isError, error } = useLocations();

    if (isLoading) return <>Loading...</>;
    if (isError) return <>Error: {error}</>;
    const locations = data ?? [];

    return (
        <CheckBoxList
            name={props.name}
            label={props.label}
            items={locations.map(l => ({
                value: String(l.locationId),
                label: l.location ?? '',
            }))}
            categorywithmembersmap={new Map<string, string[]>()}
        />
    );
};

export default ProjectLocations;
