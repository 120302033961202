import { useTranslation } from 'react-i18next';
import CollectFieldData, {
    ContraintCaptionDefinition,
    DataPointCaptionDefinition,
} from '../collectors/CollectFieldData';
import { useQuery } from 'react-query';
import { dataPointApi, DataPointDefinition } from '../api/sentinel';

const contraintsCaption: ContraintCaptionDefinition = {
    required: '{title} must be provided',
    allowedAnyOfOrNone: '{title} needs any of {options} or none',
    allowedAnyOf: '{title} needs any of {options}',
    allowedOneOf: '{title} needs one of {options}',
    greaterThanOrEqualTo: '{title} must be greater than or equal to {0}',
    lessThanOrEqualTo: '{title} must be less than or equal to {0}',
};

function adaptToLegacy(fieldData: CollectFieldData[]): CollectFieldData[] {
    setOptions(
        'deploymentTarget',
        ['NONE', 'CI', 'TEST', 'UAT', 'PROD_STAGING'],
        fieldData,
    );
    setOptions(
        'ciActivity',
        [
            'NONE',
            'COMPILES',
            'COMPILES_STATIC_ANALYSIS',
            'COMPILES_STATIC_ANALYSIS_RUNS_TESTS',
            'COMPILES_STATIC_ANALYSIS_RUNS_TESTS_GENERATES_ARTIFACT',
        ],
        fieldData,
    );
    return fieldData;
}

function setOptions(
    fieldId: string,
    options: string[],
    fieldData: CollectFieldData[],
) {
    fieldData
        .filter(data => data.dataPoint.id == fieldId)
        .forEach(data => {
            data.dataPoint.options = new Set(options);
        });
}

const useDataPointDefinition = () => {
    const { t } = useTranslation();
    const tx = (key?: string) => {
        if (!key) return undefined;
        const result = t(key);
        return result !== key ? result : undefined;
    };

    function createCaption(id: string): DataPointCaptionDefinition {
        return {
            title: t(`sprintForm_${id}_label`),
            directiveText: tx(`sprintForm_${id}_helper`),
            help: tx(`sprintForm_${id}_tooltip`),
        };
    }

    const dataDataPointDefinitionsData = useQuery<DataPointDefinition[], Error>(
        'dataDataPointDefinitions',
        async ({ signal }) =>
            dataPointApi.getAllDataPointDefinitions({ signal }),
    );

    const datapoints = dataDataPointDefinitionsData.data ?? [];
    const isLoading = dataDataPointDefinitionsData.isLoading;
    const data: CollectFieldData[] = adaptToLegacy(
        datapoints.map(data => {
            return {
                dataPoint: data,
                contraintsCaption: contraintsCaption,
                caption: createCaption(data.id),
            };
        }),
    );
    return {
        data,
        isLoading: isLoading,
    };
};

function throwException(message: string): CollectFieldData {
    throw new Error(message);
}

export function findFieldData(
    fieldData: CollectFieldData[],
    metricName: string,
): CollectFieldData {
    return (
        fieldData.find(f => f.dataPoint.id === metricName) ??
        throwException(`Field definition for metric ${metricName} not found`)
    );
}

export default useDataPointDefinition;
