import InputAdornment from '@mui/material/InputAdornment';
import TextFieldInput from '../../common/formContextBoundControls/TextFieldInput';
import CollectFieldData from '../CollectFieldData';

const NumberCollector = ({
    fieldData,
    hasRole,
    allowsDecimal,
    unit,
}: {
    fieldData: CollectFieldData;
    hasRole: (a?: string[]) => boolean;
    allowsDecimal?: boolean;
    unit?: string;
}) => {
    const enforceKeyConstraints = (
        event: React.KeyboardEvent<HTMLInputElement>,
    ) => {
        const allowedKeys = [
            'Backspace',
            'Tab',
            'Delete',
            'Home',
            'End',
            'ArrowLeft',
            'ArrowRight',
            '-',
            ...(allowsDecimal ? ['.'] : []),
        ];
        if (!/[0-9]/.test(event.key) && !allowedKeys.includes(event.key)) {
            event.preventDefault();
        }
    };
    return (
        <>
            <TextFieldInput
                label={fieldData.caption.title}
                name={fieldData.dataPoint.id}
                tooltip_title={fieldData.caption.help}
                helper_text={fieldData.caption.directiveText}
                required={
                    fieldData.dataPoint.valueConstraint?.required ?? false
                }
                disabled={
                    fieldData.dataPoint.restriction?.writeAccessRoles
                        ? !hasRole(
                              Array.from(
                                  fieldData.dataPoint.restriction
                                      .writeAccessRoles,
                              ),
                          )
                        : false
                }
                placeholder={fieldData.caption.placeholder}
                onKeyDown={enforceKeyConstraints}
                endAdornment={
                    unit && (
                        <InputAdornment position="end"> {unit} </InputAdornment>
                    )
                }
            />
        </>
    );
};

export default NumberCollector;
