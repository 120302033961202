import React from 'react';
import { Navigate } from 'react-router-dom';
import { authService } from './authentication/useAuth';

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
    if (!authService.hasReadRights()) {
        return <Navigate to={'/RequestAccess'} replace />;
    }

    return children;
};
export default ProtectedRoute;
