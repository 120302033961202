import {
    AccountInfo,
    AuthenticationResult,
    EventMessage,
    EventType,
    IPublicClientApplication,
} from '@azure/msal-browser';
import accessTokenParser, {
    UserAuthInfoType,
} from '../services/accessTokenParser';
import { graphLoginScopes, apiLoginScopes } from './authConfig';

export interface TokenProvider {
    getToken(): Promise<Token>;
}

class TokenBuilder {
    private _tokenFactory: () => Token;

    public constructor() {
        this._tokenFactory = () => {
            throw new Error(
                'TokenBuilder not configured. You must call msalToken() or fakeToken()',
            );
        };
    }

    public msalToken(tokenResult: AuthenticationResult): TokenBuilder {
        this._tokenFactory = () => new MsalToken(tokenResult);
        return this;
    }

    public fakeToken(token: string): TokenBuilder {
        this._tokenFactory = () => new SharedSecretToken(token);
        return this;
    }

    public build(): Token {
        return this._tokenFactory();
    }
}

export interface Token {
    value(): string;

    expires(): Date;

    expired(): boolean;

    userInfo(): UserAuthInfoType;
}

export class MsalToken implements Token {
    private readonly _token: AuthenticationResult;
    private readonly _decoded: UserAuthInfoType;

    public constructor(tokenResult: AuthenticationResult) {
        this._token = tokenResult;
        this._decoded = accessTokenParser(this._token.accessToken);
    }

    value(): string {
        return this._token.accessToken;
    }

    expires(): Date {
        return this._decoded.expiration;
    }

    expired(): boolean {
        return this.expires() <= new Date();
    }

    userInfo(): UserAuthInfoType {
        return this._decoded;
    }

    get account(): AccountInfo {
        return this._token.account;
    }
}

export class SharedSecretToken implements Token {
    private readonly _token: string;
    private readonly _decoded: UserAuthInfoType;

    public constructor(token: string) {
        this._token = token;
        this._decoded = accessTokenParser(this._token);
    }

    value(): string {
        return this._token;
    }

    expires(): Date {
        return this._decoded.expiration;
    }

    expired(): boolean {
        return this.expires() > new Date();
    }

    userInfo(): UserAuthInfoType {
        return this._decoded;
    }
}

export class FakeTokenProvider implements TokenProvider {
    private readonly _token: SharedSecretToken;

    constructor(token: string) {
        this._token = new TokenBuilder()
            .fakeToken(token)
            .build() as SharedSecretToken;
    }

    async getToken(): Promise<Token> {
        return this._token;
    }
}

export class MsalTokenProvider implements TokenProvider {
    private readonly _pca: IPublicClientApplication;
    private readonly _scopes: string[];
    private readonly _accountProvider: () => Promise<AccountInfo>;
    private _token: MsalToken | null;

    public constructor(
        pca: IPublicClientApplication,
        scopes: string[],
        accountProvider: () => Promise<AccountInfo>,
    ) {
        this._pca = pca;
        this._scopes = scopes;
        this._accountProvider = accountProvider;
        this._token = null;
        this._pca.addEventCallback(this.loginSuccessCallback);
    }

    async getToken(): Promise<Token> {
        const accountToUse: AccountInfo =
            this._token !== null
                ? this._token.account
                : await this._accountProvider();
        const newTokenRequired: boolean =
            this._token === null || this._token.expired();

        if (newTokenRequired && accountToUse) {
            await this._pca
                .acquireTokenSilent({
                    scopes: this._scopes,
                    account: accountToUse,
                })
                .then(response => this.applySuccessfulLogin(response));
        }

        return this._token === null
            ? Promise.reject('Token is not available')
            : Promise.resolve(this._token);
    }

    private tokenHasConfiguredScopes(tokenScopes: string[]): boolean {
        return this._scopes.every(scope => tokenScopes.includes(scope));
    }

    applySuccessfulLogin(result: AuthenticationResult) {
        if (this.tokenHasConfiguredScopes(result.scopes)) {
            this._token = new TokenBuilder()
                .msalToken(result)
                .build() as MsalToken;
            this._pca.setActiveAccount(this._token.account);
        }
    }

    loginSuccessCallback(event: EventMessage) {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            this.applySuccessfulLogin(event.payload as AuthenticationResult);
        }
    }
}

export class MsalTokenProviderBuilder {
    private readonly _defaultPcaAccountProvider: () => Promise<AccountInfo> =
        (): Promise<AccountInfo> =>
            Promise.resolve(this._pca.getAllAccounts()[0]);

    private readonly _pca: IPublicClientApplication;
    private _scopes: string[] = apiLoginScopes;
    private _accountProvider: () => Promise<AccountInfo> =
        this._defaultPcaAccountProvider;

    public constructor(pca: IPublicClientApplication) {
        this._pca = pca;
    }

    public graph(
        accountProvider: () => Promise<AccountInfo>,
    ): MsalTokenProviderBuilder {
        this._accountProvider = accountProvider;
        this._scopes = graphLoginScopes;
        return this;
    }

    public api(): MsalTokenProviderBuilder {
        this._accountProvider = this._defaultPcaAccountProvider;
        this._scopes = apiLoginScopes;
        return this;
    }

    public build(): TokenProvider {
        return new MsalTokenProvider(
            this._pca,
            this._scopes,
            this._accountProvider,
        );
    }
}

export class Providers {
    private static _applicationApiProvider: TokenProvider;
    private static _graphApiProvider: TokenProvider;

    public static get applicationApiProvider(): TokenProvider {
        return this._applicationApiProvider;
    }

    public static set applicationApiProvider(provider: TokenProvider) {
        if (provider !== this._applicationApiProvider) {
            this._applicationApiProvider = provider;
        }
    }

    public static get graphApiProvider(): TokenProvider {
        return this._graphApiProvider;
    }

    public static set graphApiProvider(provider: TokenProvider) {
        if (provider !== this._graphApiProvider) {
            this._graphApiProvider = provider;
        }
    }
}
