import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { sharedSecretToken, getPcaConfig } from './authentication/authConfig';
import {
    SharedSecretToken,
    FakeTokenProvider,
    Providers,
} from './authentication/tokenProvider';
import { MockProvider, Providers as MSProviders } from '@microsoft/mgt-element';
import { authService } from './authentication/useAuth';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

if (sharedSecretToken) {
    authService.cacheToken(new SharedSecretToken(sharedSecretToken));
    Providers.graphApiProvider = Providers.applicationApiProvider =
        new FakeTokenProvider(sharedSecretToken);
    MSProviders.globalProvider = new MockProvider(true);
    root.render(<App usingMsal={false} />);
} else {
    PublicClientApplication.createPublicClientApplication(getPcaConfig()).then(
        // render app
        pcaInstance => {
            root.render(
                <React.StrictMode>
                    <MsalProvider instance={pcaInstance}>
                        <App usingMsal={true} />
                    </MsalProvider>
                </React.StrictMode>,
            );
        },
    );
}
