import initialValues, { ProjectFormModel } from './services/projectFormModel';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { dataPointApi, DataPointDefinition, projectApi } from '../api/sentinel';
import {
    dtoToForm,
    formToDataPointsDto,
    formToDto,
} from './services/projectMapper';
import useLocations from './useLocations';
import useDataPointDefinition from '../services/useDataPointDefinition';
import { authService } from '../authentication/useAuth';

type UseProjectDataResult = {
    isLoading: boolean;
    isError: boolean;
    error: Error | null;
    formModel: ProjectFormModel;
    save: (model: ProjectFormModel) => Promise<void>;
};

const useProjectData = (
    projectId: string | undefined,
): UseProjectDataResult => {
    const dataPointDefinitions = useDataPointDefinition();
    const { data, error, isError, isLoading } = useQuery<
        ProjectFormModel,
        Error
    >(['project', projectId], async ({ signal }) => {
        if (projectId !== undefined) {
            return dtoToForm(
                projectId,
                await projectApi.getProject({ projectId }, { signal }),
                await dataPointApi.getProjectDataPointDefinitions(
                    { projectId },
                    { signal },
                ),
            );
        } else {
            const dataPointIds = dataPointDefinitions.data
                .filter(item => !!item.dataPoint.category)
                .map(d => d.dataPoint.id);
            return { ...initialValues, dataPointIds };
        }
    });
    const { data: locations } = useLocations();
    const queryClient = useQueryClient();

    const { mutateAsync } = useMutation({
        mutationFn: async (model: ProjectFormModel) => {
            const project = formToDto(model, locations ?? []);
            const datapointDefinitions = formToDataPointsDto(model);
            if (projectId !== undefined) {
                await projectApi.updateProject({
                    projectId: projectId,
                    project: project,
                });

                await setProjectDataPointDefinitions(
                    projectId,
                    datapointDefinitions,
                );
            } else {
                const response = await projectApi.saveProject({ project });
                await setProjectDataPointDefinitions(
                    response.projectId,
                    datapointDefinitions,
                );
            }
        },
        onSettled: () => {
            return queryClient.invalidateQueries(['project']);
        },
    });

    return {
        formModel: data || { ...initialValues },
        isLoading,
        isError,
        error,
        save: mutateAsync,
    };
};

const setProjectDataPointDefinitions = async (
    projectId: string | undefined,
    dataPointDefinition: DataPointDefinition[],
) => {
    const hasLeadRights = authService.hasLeadRights();

    if (
        projectId !== undefined &&
        hasLeadRights &&
        dataPointDefinition !== undefined
    ) {
        await dataPointApi.setProjectDataPointDefinitions({
            projectId,
            dataPointDefinition,
        });
    }
};

export default useProjectData;
